<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";
import store from "@/state/store";

export default {
  data() {
    return {
      doctor: [],
      msg: '',
      permission: store.getters["login/permission"]
    };
  },
  components: {
    Layout,
  },
  methods: {
    async getData() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctor/"
      );
      this.doctor = response.data;
      console.log(this.doctor)
    },

    async deleteDoctor(id) {
      const user = await axios.delete(
        "https://api.doctosoft.com/index.php/api/doctor/" + id
      );
      this.msg = user.data;
      this.getData();
    },
    addButton() {
      this.$router.push({
        path: '/admin/add-doctor'
      });
    },
    updateData(id) {
      if (id) {
        this.$router.push("/admin/update-doctor/" + id);
      } else {
        this.$router.push("/admin/add-doctor")
      }
    },
    confirm(id) {
      Swal.fire({

        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value === true) {
          Swal.fire("Deleted!", "Deleted a doctor successfully " , "success");

          this.deleteDoctor(id)

        }
      });
    },
    async changeStatus(doctor) {
      var urlencoded = new URLSearchParams();
      urlencoded.append("status", !doctor.status?1:0);
      console.log(urlencoded);
      await axios.put(
        "https://api.doctosoft.com/index.php/api/doctor/" + doctor.id,
        urlencoded
      );
      console.log("hello")
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<template>
  <Layout>
    <b-row>
      <b-col lg="12">
        <b-card no-body id="doctorTable">
          <b-card-header class="d-flex align-items-center">
            <h5 class="card-title flex-grow-1 mb-0">Doctor's Table</h5>
            <div class="d-flex gap-1 flex-wrap">
              <!--<b-button variant="soft-danger" id="remove-actions" onClick="deleteMultiple()"><i
                    class="ri-delete-bin-2-line"></i>
                </b-button>-->
              <b-link class="btn btn-success button create-btn" type="button" id="docadd-btn" @click="addButton"><i
                  class="ri-add-line align-bottom me-1"></i>
                Add </b-link>
            </div>
          </b-card-header>
          <b-card-body>
            <div>
              <div class="table-responsive table-card mb-3">
                <table class="table align-middle table-nowrap mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col" style="width: 50px;">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkAll" value="option">
                        </div>
                      </th>
                      <th class="sort" data-sort="name" scope="col">Name</th>
                      <th class="sort" data-sort="email" scope="col">Email</th>
                      <!-- <th class="sort" data-sort="image" scope="col">Image</th> -->
                      <th class="sort" data-sort="phone" scope="col">Phone</th>
                      <th class="sort" data-sort="address" scope="col">Address</th>
                      <th class="sort" data-sort="expert" scope="col">Expert</th>
                      <th class="sort" data-sort="degree" scope="col">Degree</th>
                      <th class="sort" data-sort="status" scope="col">Status</th>
                      <th scope="col" v-if="this.permission.doctor.update || this.permission.doctor.delete">Action</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="doc in doctor" :key="doc.id">
                      <th scope="row">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="chk_child" value="option1">
                        </div>
                      </th>
                      <td class="name">{{ doc.name }}</td>
                      <td class="email">{{ doc.email }}</td>
                      <!-- <td class="image">{{ doc.image }}</td> -->
                      <td class="phone">{{ doc.phone }}</td>
                      <td class="address">{{ doc.address }}</td>
                      <td class="expert">{{ doc.expert }}</td>
                      <td class="degree">{{ doc.degree }}</td>
                      <td class="status"><b-badge variant="badge-soft-success" :class="{
                        'badge-soft-success': doc.status == true,
                        'badge-soft-danger': doc.status == false,
                      }">{{ doc.status }}</b-badge></td>
                      <div class="dropdown">
                        <b-button variant="soft-secondary" size="sm" class="dropdown" type="button"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="ri-more-fill align-middle"></i>
                        </b-button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <b-link class="dropdown-item edit-item-btn" id="edit-item-btn"
                              @click="(updateData(doc.id))" v-if="this.permission.doctor.update">Edit</b-link>
                          </li>
                          <li>
                            <b-button class="dropdown-item disable-btn" id="disable-btn" @click="changeStatus(doc)">{{
                              doc.status ? "Disable" : "Active"
                            }} </b-button>
                          </li>
                          <li>
                            <b-button class="dropdown-item remove-item-btn" @click="confirm(doc.id)" v-if="this.permission.doctor.delete">Delete</b-button>
                          </li>
                        </ul>
                      </div>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="noresult" style="display: none">
                    <div class="text-center">
                      <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                        :height="75" :width="75" />
                      <h5 class="mt-2">Sorry! No Result Found</h5>
                      <p class="text-muted mb-0">We've searched more than 150+ API Keys We did not find any API for you
                        search.</p>
                    </div>
                  </div> -->
              </div>
              <!-- <div class="d-flex justify-content-end mt-3">
                  <div class="pagination-wrap hstack gap-2">
                    <b-link class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                      Previous
                    </b-link>
                    <ul class="pagination listjs-pagination mb-0">
                      <li :class="{ active: pageNumber == page, disabled: pageNumber == '...', }"
                        v-for="(pageNumber, index) in pages" :key="index" @click="page = pageNumber">
                        <b-link class="page" href="#">{{ pageNumber }}</b-link>
                      </li>
                    </ul>
                    <b-link class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                      Next
                    </b-link>
                  </div> -->
              <!-- </div> -->
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
</Layout>
</template>

<style scoped>
.addUser-btn {
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-end;
}

i.ri-delete-bin-line,
i.ri-edit-2-line {
  cursor: pointer;
}
</style>